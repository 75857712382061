<template>
  <div>
    <section
      class="hero"
      :style="backgroundStyle"
    >
      <div class="hero-body">
        <div
          class="container is-max-desktop"
          :class="{ 'px-5': $mq !== 'mobile' }"
        >
          <div class="box search-box">
            <div class="p-2">
              <p class="title is-4 is-size-5-mobile has-text-weight-bold">
                <span v-if="!$motors">Hi, enter the vehicle you want&nbsp;to&nbsp;look&nbsp;up</span>
                <span
                  v-if="$motors"
                  class="is-size-5"
                >Check the value and price history of any vehicle</span>
              </p>
              <DashboardSearch />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <HistoryTable :preview="true" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {
    HistoryTable: () =>
      import('modules/account/components/history/AccountHistoryTable'),
    DashboardSearch: () =>
      import('modules/dashboard/components/DashboardSearch')
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage:
          'url(' + require(`core/img/background/${process.env.VUE_APP_TYPE}-pattern.png`) + ')',
        backgroundSize: 'cover'
      }
    }
  }
}
</script>

<style lang="sass">
.search-box
  background-color: $search-box-colour-background
</style>